import React, { Fragment } from 'react'
import { SharedEvaluatorsContainer } from './SharedEvaluators'
import { AdditionalEvaluatorsContainer } from './AdditionalEvaluators'
import { connect } from 'react-redux'
import { If } from 'simple-core-ui'
import { Notification } from 'matters/templates/common/BaseEvaluators/Notification'
import * as ACT from './actions'

@connect(({ app, surveyConfig }, ownProps) => {
  return {
    entityId: ownProps.scopeId ?? app.scope.id,
    status: surveyConfig.sharedEvaluators.status
  }
})
class SurveyConfigTabContainer extends React.Component {
  state = {
    shouldNotify: true,
    hasChangedSection: false
  }
  componentDidMount() {
    this.props.dispatch({
      type: ACT.CONTACT_ADDRESS_BOOK_FETCH_REQUESTED
    })

    this.props.dispatch({
      type: ACT.SURVEY_CONFIG_ROLES_FETCH_REQUESTED,
      payload: {
        entityId: this.props.entityId
      }
    })

    this.props.dispatch({
      type: ACT.SURVEY_CONFIG_ADDITIONAL_EVALUATORS_FETCH_REQUESTED,
      payload: {
        entityId: this.props.entityId
      }
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.status !== prevProps.status && this.props.status === 'enabled') {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        shouldNotify: false
      })
    }
  }
  changeAttributeValue = async ({ value, property, type, index }) => {
    await this.props.dispatch({
      type,
      payload: {
        value,
        property,
        index
      }
    })
  }
  onKeepInactive = () => {
    this.setState({
      shouldNotify: false
    })
  }
  onMakeActive = () => {
    this.changeStatus({ value: 'enabled' })

    window._setSurveyStatusToTemplateDefault('enabled', true)
  }
  changeStatus = status => {
    this.props.dispatch({
      type: ACT.SURVEY_CONFIG_SHARED_CHANGE_STATUS,
      payload: {
        status: status.value
      }
    })
  }
  hasChangedSection = () => {
    this.setState({
      hasChangedSection: true
    })
  }
  render() {
    return (
      <If
        condition={
          this.state.shouldNotify &&
          this.state.hasChangedSection &&
          this.props.status === 'disabled'
        }
        fallback={
          <Fragment>
            <SharedEvaluatorsContainer
              changeAttributeValue={this.changeAttributeValue}
              entityId={this.props.entityId}
              hasChangedSection={this.hasChangedSection}
              readOnly={this.props.readOnly}
            />
            <AdditionalEvaluatorsContainer
              changeAttributeValue={this.changeAttributeValue}
              hasChangedSection={this.hasChangedSection}
              readOnly={this.props.readOnly}
            />
          </Fragment>
        }
      >
        <Notification onMakeActive={this.onMakeActive} onKeepInactive={this.onKeepInactive} />
      </If>
    )
  }
}

export default SurveyConfigTabContainer
