import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

const ActionsPopover = ({
  rowId,
  downloadPdf,
  exportCsv,
  hasResendNotification,
  resendApprovalEmails
}) => {
  const actions = [
    {
      name: 'DOWNLOAD_PDF',
      text: 'Download PDF',
      onClick() {
        downloadPdf(rowId)
      }
    },
    {
      name: 'EXPORT_CSV',
      text: 'Export CSV',
      onClick() {
        exportCsv(rowId)
      }
    },
    {
      name: 'RESEND_APPROVAL',
      text: 'Resend Approval Emails',
      visible: hasResendNotification,
      onClick() {
        resendApprovalEmails(rowId)
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction
          tip="More"
          icon={<IoIosMore style={{ fontSize: '20px' }} />}
          size="xsmall"
          className={s.hoverAction}
          hasNewDesign
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
