import { FileContainer } from './FileContainer'

const UploadProgress = ({
  files,
  attachments,
  currentFolder,
  dispatch,
  setUploadsFinishedNo,
  cancelFileUpload,
  setCanCancelFilesArr,
  matterId
}) => {
  if (!files.length) {
    return <p>No files are uploading</p>
  }
  return files.map(file => (
    <FileContainer
      dispatch={dispatch}
      currentFolder={currentFolder}
      attachments={attachments}
      file={file}
      setUploadsFinishedNo={setUploadsFinishedNo}
      key={file.id || `${file.name}${file.lastModified}`}
      cancelFileUpload={cancelFileUpload}
      setCanCancelFilesArr={setCanCancelFilesArr}
      matterId={matterId}
    />
  ))
}

export default UploadProgress
