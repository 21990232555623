import { ListTableContainer } from 'containers'
import capitalize from 'lodash/capitalize'
import { REQUEST_STATUSES } from 'matters/requests/constants'
import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'simple-core-ui/hocs'
import { RequestModalContainer } from './RequestModalContainer'
import { normalizeCellContent } from './utils'

@withRouter
@connect(({ requestsList }) => {
  const { requests } = requestsList

  const legalRequests = requests.map(request => {
    return {
      ...request
    }
  })

  return {
    requestsList: legalRequests
  }
})
class RequestsTableContainer extends Component {
  state = {
    showRequestModal: false,
    currentRequestId: null
  }
  componentDidMount() {
    const id = this.props.router.params.id
    if (id) {
      this.setState({
        showRequestModal: true,
        currentRequestId: id
      })
    }
  }

  viewRequestDetail = request => {
    this.setState(prevState => ({
      showRequestModal: !prevState.showRequestModal,
      currentRequestId: request.id
    }))
  }

  toggleRequestModal = () => {
    this.setState(prevState => ({ showRequestModal: !prevState.showRequestModal }))
  }

  render() {
    const {
      requestsList,
      columns,
      saveBtnText,
      closeBtnText,
      confirmCb,
      requestsAvailable,
      cancelCb,
      secondaryConfirmCb,
      secondaryConfirmText,
      hideCancelBtn,
      title,
      initialCategory,
      getSecondaryAction
    } = this.props

    const { PENDING, APPROVED, REJECTED } = REQUEST_STATUSES

    const filterListItems = [
      { label: 'All', value: 'All' },
      {
        label: capitalize(PENDING),
        value: capitalize(PENDING)
      },
      {
        label: capitalize(APPROVED),
        value: capitalize(APPROVED)
      },
      {
        label: capitalize(REJECTED),
        value: capitalize(REJECTED)
      }
    ]
    const entryLanguage = {
      singular: 'request',
      plural: 'requests'
    }

    const primaryAction = requestsAvailable
      ? {
          href: '/v2/matters/requests/create',
          testid: 'create_request_btn',
          children: 'New Request'
        }
      : null

    return (
      <Fragment>
        <ListTableContainer
          objects={requestsList}
          columns={columns}
          filterListItems={filterListItems}
          clickRowAction={this.viewRequestDetail}
          getNormalizedCellContent={normalizeCellContent}
          entryLanguage={entryLanguage}
          title={title}
          initialCategory={initialCategory}
          primaryAction={primaryAction}
          getSecondaryAction={getSecondaryAction || null}
        />
        {this.state.showRequestModal && (
          <RequestModalContainer
            id={this.state.currentRequestId}
            saveBtnText={saveBtnText}
            closeBtnText={closeBtnText}
            confirmCb={confirmCb}
            cancelCb={cancelCb}
            closeCb={this.toggleRequestModal}
            secondaryConfirmCb={secondaryConfirmCb}
            secondaryConfirmText={secondaryConfirmText}
            hideCancelBtn={hideCancelBtn}
          />
        )}
      </Fragment>
    )
  }
}

export default RequestsTableContainer
