import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import moment from 'moment'
import { REQUEST_EXTRA_FIELDS } from './constants'

export const getRequestItemValue = value => {
  return value && typeof value === 'object' && value !== null
    ? `${value.amount} ${value.currency_code}`
    : value
}

export const getRequestRepresentation = additionalFields => {
  const fields = cloneDeep(additionalFields)
  fields &&
    fields.map(field => {
      if (
        [REQUEST_EXTRA_FIELDS.REVIEWED_BY, REQUEST_EXTRA_FIELDS.COMMENTS].includes(field.key) &&
        !field.value
      ) {
        field.value = '-----'
      }
      return field
    })
  return fields
}

export const limitCommentCharacters = comment => {
  const charLimit = 100
  return comment.length > charLimit ? comment.substring(0, charLimit - 3) + '...' : comment
}
/**
 *
 * @param {string} columnKey The column on the requests table container to be used as the sort key.
 * @param {Array} row A full row of the table, which will be mapped to the content of the sort column's cell during sorting.
 * @returns The content of the cell in the column specified by `columnKey`, in a given row from the table.
 */
export const normalizeCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'name':
    case 'requested_by_user':
    case 'request_form_name':
    case 'reviewed_by_user':
    case 'status':
    case 'matterStatus':
    case 'clientMatterId':
    case 'comment':
      return cellContent.toString().toLowerCase()

    case 'createdDate':
    case 'lastModified':
    case 'lastStatusUpdated':
      return moment(cellContent, 'MM/DD/YYYY').unix()

    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}
