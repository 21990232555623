import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import debounce from 'debounce-promise'
import { components } from 'react-select'
import { FaSearch, FaTimes } from 'react-icons/fa'
import classNames from 'classnames'
import pluralize from 'pluralize'

import { StackList } from 'components'
import { normalizeTeam } from '../../reviews/reviewer_config/utils/utils'

import { OutsideClickContainer, PaginatedSelect, Ellipsis } from 'simple-core-ui'
import { SelectCustomInput } from 'common/Selects'
import { makeGetRequest } from 'simple-core-ui/utils/api'

import s from './TeamSelect.scss'
import { toUsers } from '../serializers'

const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      <StackList stacks={[normalizeTeam(props.data)]} />
    </components.SingleValue>
  )
}

const MultiValueLabel = props => {
  return (
    <components.MultiValueLabel {...props}>
      <StackList stacks={[normalizeTeam(props.data)]} />
    </components.MultiValueLabel>
  )
}

const Option = props => {
  return (
    <components.Option {...props}>
      <StackList stacks={[normalizeTeam(props.data)]} />
    </components.Option>
  )
}

const CustomTeamsSelect = ({ value, url, dbWait = 400, serializer, resultsProperty, onChange }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [options, setOptions] = useState([])
  const [searchOptions, setSearchOptions] = useState([])
  const [searchBy, setSearchBy] = useState('')
  const dispatch = useDispatch()

  const fetchOptions = async (search = '') => {
    try {
      const response = await makeGetRequest(url, !!search && { params: { search_term: search } })
      const options = serializer(response[resultsProperty])
      !!search ? setSearchOptions(options) : setOptions(options)
      setSearchBy(search)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedFetchOptions = debounce(search => fetchOptions(search), dbWait)

  const setInitState = () => {
    setIsExpanded(false)
    setSearchBy('')
  }

  const getListOptions = () => {
    const selectedValues = value.map(({ value }) => value)
    return (searchBy ? searchOptions : options).filter(
      option => !selectedValues.includes(option.value)
    )
  }

  const getSelectedValue = () => {
    return value.length ? (
      <div className={s.selectedValueContainer}>
        {value.map(v => {
          const selectedOption = options.find(option => option.value === v.value)
          const { description, memberCount, label } = selectedOption || v
          return (
            <div className={s.selectedValue} key={v.value}>
              <div className={s.valueSection}>
                <div className={s.label}>
                  <Ellipsis width={200}>{label}</Ellipsis>
                </div>
                <div className={s.extraInfo}>{`${description ? description + ' ' : ''}(${pluralize(
                  'member',
                  memberCount,
                  true
                )})`}</div>
              </div>
              <div
                className={s.clearIconContainer}
                onClick={() => onChange(value.filter(({ value }) => value !== v.value))}
              >
                <FaTimes className={s.clearIcon} />
              </div>
            </div>
          )
        })}
      </div>
    ) : null
  }

  return (
    <OutsideClickContainer closeComponent={setInitState}>
      <section className={classNames(s.container, { [s.expanded]: isExpanded })}>
        <SelectCustomInput
          value={getSelectedValue()}
          placeholder="Select teams..."
          onClick={() =>
            setIsExpanded(expanded => {
              expanded && setSearchBy('')
              return !expanded
            })
          }
          onClear={() => onChange([])}
        />
        {isExpanded && (
          <div className={s.expandableContainer}>
            <div className={s.searchInputContainer}>
              <FaSearch className={s.searchIcon} />
              <input
                onChange={e => {
                  const { value } = e.target
                  debouncedFetchOptions(value)
                }}
                className={s.searchInput}
                placeholder="Search"
              />
            </div>
            <div className={s.searchDivider} />
            <div className={s.listContainer}>
              {getListOptions().map(option => {
                const { description, memberCount } = option
                return (
                  <div
                    className={s.option}
                    key={option.value}
                    onClick={() => {
                      onChange([...value, option])
                      setInitState()
                    }}
                  >
                    <div className={s.label}>
                      <Ellipsis width={200}>{option.label}</Ellipsis>
                    </div>
                    <div className={s.extraInfo}>{`${
                      description ? description + ' ' : ''
                    }(${pluralize('member', memberCount, true)})`}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </section>
    </OutsideClickContainer>
  )
}

const TeamSelect = ({
  id,
  onChange,
  value,
  isDisabled,
  styles,
  searchParams,
  url = '/teams/search/',
  cappedInputHeight,
  multi,
  ariaLabel,
  placeholder,
  limit,
  serializer,
  isClearable,
  customQueryParams,
  maxMenuHeight,
  onMenuOpen,
  filterOption,
  menuIsOpen,
  closeMenuOnSelect,
  resultsProperty = 'results',
  withSearchBar
}) => {
  return !withSearchBar ? (
    <PaginatedSelect
      id={id}
      url={url}
      value={value}
      className={classNames({
        [s.multi]: multi,
        [s.single]: !multi,
        [s.capped]: cappedInputHeight
      })}
      onChange={(value, action) => onChange(value || [], action)}
      isMulti={multi}
      serializer={serializer || toUsers}
      isClearable={isClearable}
      searchTermParam={searchParams}
      placeholder={placeholder}
      hasMoreProperty="more"
      resultsProperty={resultsProperty}
      limit={limit || 100}
      comps={{ MultiValueLabel, Option, SingleValue }}
      aria-label={ariaLabel}
      isDisabled={isDisabled}
      mandatoryQueryParams={customQueryParams}
      styles={styles}
      closeMenuOnSelect={closeMenuOnSelect}
      maxMenuHeight={maxMenuHeight}
      onMenuOpen={onMenuOpen}
      filterOption={filterOption}
      menuIsOpen={menuIsOpen}
    />
  ) : (
    <CustomTeamsSelect
      value={value}
      url={url}
      resultsProperty={resultsProperty}
      serializer={serializer || toUsers}
      onChange={value => onChange(value)}
    />
  )
}

export default TeamSelect
