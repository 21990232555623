import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ModalContainer } from 'simple-core-ui'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { serializeSharedWithUsers } from './serializer'
import APP_ACT from 'app/actions'
import { CONTACTS_SEARCH_URL } from 'common/Selects/urls'
import { CgUser, CustomQueryParams, ShareFormType, User } from './types'
import { FileShareForm } from './FileShareForm'
import { isBasicTaskManagementPlan } from 'utils/helpers'

interface Props {
  fileId: string
  fileName: string
  sharedWithUsers: CgUser[]
  closeShareModal: () => void
  customQueryParams: CustomQueryParams
  updateFileData: (fileId: string, sharedWithUsers: CgUser[]) => void
  footerContent?: JSX.Element | string
}

const initialShareFormValues: ShareFormType = {
  selectedCgUser: [],
  sharedWithUsers: [],
  notifyViaEmail: true,
  note: '',
  isUserEdited: false,
  isFileShared: false,
  displayMsg: ''
}

const isBasicPlan = isBasicTaskManagementPlan()

const FileShareModal = ({
  fileId,
  fileName,
  sharedWithUsers,
  closeShareModal,
  customQueryParams,
  updateFileData,
  footerContent
}: Props) => {
  const initialFormValues = {
    ...initialShareFormValues,
    sharedWithUsers: sharedWithUsers,
    isFileShared: sharedWithUsers.length > 0
  }
  const [shareFormValue, setShareFormValue] = useState(initialFormValues)
  const [isFileShareLimitedVendor, setIsFileShareLimitedVendor] = useState(false)
  const reduxDispatch = useDispatch()

  useEffect(() => {
    const getVendors = async () => {
      try {
        const response = await makeGetRequest(CONTACTS_SEARCH_URL, {
          params: { l: true, ...customQueryParams }
        })
        const fileShareLimitedVendors = ((response.results || []) as User[]).filter(
          v => v.org?.file_sharing_limit_exceeded
        )
        setIsFileShareLimitedVendor(!!fileShareLimitedVendors.length)
      } catch (error) {
        reduxDispatch({ type: 'API_ERROR', error })
      }
    }
    if (isBasicPlan) {
      getVendors()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCgUserIdList = (cgUsers: CgUser[]) => {
    return cgUsers.map(cgUser => cgUser['cgUserId'])
  }

  const clearShareFormValues = () => {
    setShareFormValue(initialShareFormValues)
    closeShareModal()
  }

  const shareUnShareFile = async () => {
    const sharedWithUsersList = getCgUserIdList(shareFormValue.sharedWithUsers)
    const cgUserList = getCgUserIdList(shareFormValue.selectedCgUser)

    const url = `/doc_management/native_docs/file_sharing/${fileId}/`
    try {
      const response = await makePostRequest(url, {
        email_notify: shareFormValue.notifyViaEmail,
        additional_details: shareFormValue.note,
        cg_user_ids: [...sharedWithUsersList, ...cgUserList]
      })

      updateFileData(fileId, response.shared_with_users)

      if (shareFormValue.isUserEdited) {
        setShareFormValue({
          ...initialShareFormValues,
          isFileShared: Boolean(response.shared_with_users.length),
          sharedWithUsers: serializeSharedWithUsers(response.shared_with_users),
          displayMsg: 'File sharing successfully revoked'
        })
        setTimeout(() => {
          // display the msg for 10 secs
          setShareFormValue(prevFormValue => ({
            ...prevFormValue,
            displayMsg: prevFormValue.isUserEdited ? prevFormValue.displayMsg : ''
          }))
        }, 10000)
      } else {
        reduxDispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'File successfully shared.',
            level: 'success'
          }
        })
        clearShareFormValues()
      }
    } catch (e) {
      let errorMsg = 'There was a problem sharing the file.'
      if (axios.isAxiosError(e)) {
        errorMsg = e?.response?.data?.error || errorMsg
      }
      reduxDispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: errorMsg,
          level: 'error'
        }
      })
    }
  }

  const onChangeHandler = (key: string, value: boolean | string | CgUser[] | null) => {
    setShareFormValue(prevFormValue => ({ ...prevFormValue, [key]: value }))
  }

  const removeCgUser = (value: CgUser[]) => {
    setShareFormValue({
      ...shareFormValue,
      isUserEdited: true,
      sharedWithUsers: value,
      displayMsg: 'Click Save to continue'
    })
  }

  const footerStyle = {
    justifyContent:
      footerContent && isBasicPlan && isFileShareLimitedVendor ? 'space-between' : 'flex-end'
  }

  return (
    <ModalContainer
      testid="file_share_modal"
      title={`Share '${fileName}'`}
      size="lg"
      cancelCb={clearShareFormValues}
      confirmCb={shareUnShareFile}
      confirmText={shareFormValue.isUserEdited ? 'Save' : 'Share'}
      content={
        <FileShareForm
          onChangeHandler={onChangeHandler}
          formValues={shareFormValue}
          removeCgUser={removeCgUser}
          customQueryParams={customQueryParams}
          fileName={fileName}
        />
      }
      footerContent={isBasicPlan && isFileShareLimitedVendor && footerContent}
      hasNewButtons
      shouldModalClose={false}
      isDisabled={
        !(shareFormValue.selectedCgUser.length || shareFormValue.isUserEdited) ||
        shareFormValue.note?.length > 250
      }
      footerStyle={footerStyle}
    />
  )
}

export default FileShareModal
